import React, { createContext, useState, useRef } from 'react'
import { Modal } from './model'

export const ModalContext = createContext<Modal>({
    anchorEl: null,
    onSetAnchorEl: () => undefined,
    anchorRef: null,
    welcomeModal: false,
    onSetWelcomeModal: () => undefined,
    emailModal: false,
    onSetEmailModal: () => undefined,
    myriaWalletModal: false,
    onSetMyriaWalletModal: () => undefined,
    createWalletModal: false,
    onSetCreateWalletModal: () => undefined,
    emailLinked: false,
    onSetEmailLinked: () => undefined
})

interface Props {
    children: React.ReactNode
}

const ModalProvider: React.FC<Props> = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const onSetAnchorEl = (anchorEl: HTMLButtonElement | null) => setAnchorEl(anchorEl)
    const anchorRef = useRef()
    const [welcomeModal, setWelcomeModal] = useState<boolean>(false)
    const [emailModal, setEmailModal] = useState<boolean>(false)
    const [createWalletModal, setCreateWalletModal] = useState<boolean>(false)
    const [emailLinked, setEmailLinkedModal] = useState<boolean>(false)
    const [myriaWalletModal, setmMriaWalletModal] = useState<boolean>(false)

    const onSetWelcomeModal = (welcomeModal: boolean) => setWelcomeModal(welcomeModal)
    const onSetEmailModal = (emailModal: boolean) => setEmailModal(emailModal)
    const onSetMyriaWalletModal = (myriaWalletModal: boolean) => setmMriaWalletModal(myriaWalletModal)
    const onSetCreateWalletModal = (createWalletModal: boolean) => setCreateWalletModal(createWalletModal)
    const onSetEmailLinked = (emailLinked: boolean) => setEmailLinkedModal(emailLinked)


    return (
        <ModalContext.Provider value={{
            anchorEl,
            onSetAnchorEl,
            anchorRef,
            welcomeModal,
            emailModal,
            createWalletModal,
            emailLinked,
            myriaWalletModal,
            onSetWelcomeModal,
            onSetEmailModal,
            onSetCreateWalletModal,
            onSetEmailLinked, 
            onSetMyriaWalletModal
        }}>
            {children}
        </ModalContext.Provider>
    )
}

export default ModalProvider