import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { lazy } from "react";
import config from "./config/config";

const Home = lazy(() => import('./pages/Home'))
const SolarFestival = lazy(() => import('./pages/SolarFestival'))
const NotFound = lazy(() => import('./pages/NotFound'))

function Routers(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
            <Route path={config.router.home} element={<Home />} />
                <Route path={config.router.solarFestival} element={<SolarFestival />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Routers