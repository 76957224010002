import React from 'react'
import { ThemeProvider } from '@mui/material'
import { store } from './state/store';
import ModalProvider from './contexts/MyriaModal/ModalProvider';
import { AuthenticationProvider } from './contexts/Authentication/Authentication';
import { Provider } from 'react-redux';
import { L2WalletProvider } from './contexts/l2-wallet/l2-wallet';
import { WalletProvider } from './contexts/Wallet/Wallet';
import theme from './theme'
import { QueryClient, QueryClientProvider } from 'react-query';
import toastStyle from './styles/toast.module.scss'
import { ToastContainer } from 'react-toastify';

export const queryClient = new QueryClient();
interface Props {
    children: React.ReactNode;
}

const Providers: React.FC<Props> = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <ToastContainer hideProgressBar className={toastStyle.toast} />
            <WalletProvider>
                <Provider store={store}>
                    <L2WalletProvider>
                        <AuthenticationProvider>
                            <ModalProvider>
                                <ThemeProvider theme={theme}>
                                    {children}
                                </ThemeProvider>
                            </ModalProvider>
                        </AuthenticationProvider>
                    </L2WalletProvider >
                </Provider>
            </WalletProvider>
        </QueryClientProvider>
    )
}

export default Providers