interface Config {
    api: {
        email: string
        myria: string
        solarFestival: string
    },
    router: {
        home: string
        solarFestival: string
    }
}

const config: Config = {
    api: {
        email: "https://dev.myriaweb-api.nonprod-myria.com/subscribe-moonville-site",
        myria: process.env.REACT_APP_MYRIA_API_URL ?? '',
        solarFestival: process.env.REACT_APP_API_URL ?? '',
    },
    router: {
        home: "/",
        solarFestival: "/solar-festival",
    }

}

export const localStorageKeys = {
    language: 'LANG',
    firstTime: 'FIRST_TIME',
    referralCode: 'REFERRAL_CODE',
    starkKey: 'STARK_KEY',
    walletAddress: 'WALLET_ADDRESS',
    isFirstTime: 'isFirstTime',
};
export default config
