import { configureStore } from '@reduxjs/toolkit';
import accountSlice from './slices/accountSlice';
import ticketObj from './slices/ticketObj';
import userTicketSlice from './slices/userTicketSlice';
import superNovaTicket from './slices/supernovaTicketObj'

export const store = configureStore({
    reducer: {
        account: accountSlice,
        ticketObj,
        userTicketSlice,
        superNovaTicket,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
