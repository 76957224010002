import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { setUserTicket } from '../../state/slices/userTicketSlice';
import apiClient, { noCacheApiClient, apiClientMyriaCore } from '../../util/client'
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { getModuleFactory } from '../../services/myriaCoreSdk';
import useLocalStorage from '../../hooks/useLocalStorage';
import { localStorageKeys } from '../../config/config';
//import useLocalStorage from '../../hooks/useLocalStorage';
import { useWalletContext } from "../Wallet/Wallet"
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
//import { localStorageKeys } from '../../config/config';
import { SplitSignature } from 'myria-core-sdk/dist/types';

type RegisterData = {
    ethAddress: string
    starkKey: string
    referrerId?: string | null
    signature?: SplitSignature
}

export type Task = {
    callToAction: string;
    pk: string;
    repetitionLimit: number;
    sk: string;
    starsValue: number;
    taskDescription: number;
    taskId: string;
    taskStatus: string;
    taskTitle: string;
    totalStars: number;
}

export type Ticket = {
    pk: string;
    purchaseDate: string;
    sk: string;
    ticketNumber: number;
    ticketStatus: string;
    ticketType: string;
    weekNumber: number;
}

export type User = {
    availableStars: number;
    createdAt: string;
    currentWeekTickets: Ticket[];
    earnedStars: number;
    pk: string;
    referredPurchases: string[];
    referredUsers: string[];
    sk: string;
    tasks: Task[];
    date_registered?: Date;
    updatedAt: string;
    ticketCount: number;
    ticketPrice: number;
};

export type MyriaUser = {
    user_id: string;
    wallet_id: string;
}

interface IAuthenticationContext {
    user: User | undefined;
    logout: () => void;
    loginByWalletMutation: UseMutationResult<User, unknown, void, unknown>;
    userProfileQuery: UseQueryResult<User | null, unknown>;
}

const AuthenticationContext = React.createContext<IAuthenticationContext>(
    {} as IAuthenticationContext
);

const getSignatureMessage = (ts: number) => {
    return `Welcome to Myria!\n\nSelect 'Sign' to create and sign in to your Myria account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\n${JSON.stringify(
        { created_on: ts }
    )}`;
};

interface Props {
    children: React.ReactNode
}

export const AuthenticationProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = React.useState<User | undefined>()
    const { signMessage, address } = useWalletContext();
    const [localStarkKey, setLocalStarkKey] = useLocalStorage(localStorageKeys.starkKey, '');
    const [, setWalletAddress] = useLocalStorage(localStorageKeys.walletAddress, '')
    const dispatch = useDispatch()
    
    const registerAccountService = async () => {
        const message = JSON.stringify({ created_on: new Date(Date.now() + 60000) }) // add 1 minute to current time
        const signature = await signMessage(message)
        
        if (signature && address) {
            const registerData = {
                wallet_id: address,
                signature,
                message
            }
            const userRes = await apiClientMyriaCore
                .post(`/accounts/register/wallet`, registerData)
                .then((res) => res)
            if (userRes.data?.status === 'success' && userRes?.data.data) {
                const user: MyriaUser = {
                    user_id: userRes.data.data?.user_id,
                    wallet_id: userRes.data.data?.wallet_id
                }
                return user
            } else {
                throw new Error('Failed to register user by wallet')
            }
        }
        throw new Error('Signature and wallet address are required to register')

    }

    const logoutMutation = useMutation(async () => {
        try {
            await apiClient.post(`/accounts/logout`);
        } catch (err) { console.log("logout error", err) }
        window.location.reload();
    });

    const loginByWalletMutation = useMutation(async () => {
        const timestamp = Date.now()
        const message = getSignatureMessage(timestamp)
        const signature = await signMessage(message)
        const moduleFactory = await getModuleFactory()
        const commonModule = moduleFactory.getCommonModule()
        const userModule = moduleFactory.getUserManager();
        
        if (address === undefined) throw new Error("Address cant be empty")
        
        let userRes: AxiosResponse | null;
        let registerData: RegisterData;
        
        if (signature) {
            const isCheckFirstTimeUser = localStorage.getItem(localStorageKeys.isFirstTime) === 'true';
            if (!isCheckFirstTimeUser) {
                const userData = userModule.getUserByWalletAddress(address);
                registerData = {
                    ethAddress: address,
                    starkKey: (await userData).starkKey,
                }
                userRes = await apiClient
                    .post(`/solar-festival/user`, registerData)
                    .then((res) => res);
            } else {
                // Signature genarte stark key
                let newStarkKey = await commonModule.generateStarkKey(address);
                newStarkKey = '0x' + newStarkKey

                //Set LocalStorage Local StarkKey and WalletAddress
                setLocalStarkKey(newStarkKey);
                setWalletAddress(address);
                const user = await registerAccountService()
                const signatureData: RegisterData = {
                    ethAddress: address,
                    starkKey: newStarkKey
                }
                
                const starkSignature = await commonModule.generateStarkSignatureForRegisterUser(signatureData)
                let isReferCode = ''
                const paramsString = window.location.search;
                const searchParams = new URLSearchParams(paramsString);
                if(searchParams.get('referCode')) {
                    isReferCode = searchParams.get('referCode') || '';
                }
                if (user) {
                    registerData = {
                        ethAddress: address,
                        starkKey: newStarkKey,
                        referrerId: (isReferCode?.length) ? isReferCode: undefined ,
                        signature: starkSignature
                    }
                    userRes = await apiClient.post(`/solar-festival/user/l2/wallet`, registerData).then((res) => res)
                } else {
                    userRes = null
                }
            }
            
            if (userRes && userRes.status === 201 && userRes.data.data) {
                const user: User = userRes.data.data
                toast('Login success', { type: 'success' });
                userProfileQuery.refetch();
                return user;
            } else {
                toast('Login failed, please try again.', { type: 'error' });
                throw new Error('Failed to login user by wallet');
            }
        }
        throw new Error('Signature and wallet address are required to login')
    });

    const logout = () => {
        logoutMutation.mutate();
    };

    const userProfileQuery = useQuery(
        'getUserProfile',
        () => {
            if(localStarkKey.length > 0){
                return (noCacheApiClient.get(`/solar-festival/user/${localStarkKey}`).then((res) => {
                    if (res.status === 200 && res.data.data) {
                        if(localStarkKey) {
                            const user: User = res.data.data;
                            setUser(user);
                            dispatch(setUserTicket( {
                                availableStars: user.availableStars,
                                userTicketBought: user.currentWeekTickets.length
                            }))
                            return user;
                        }
                    }
                    return null;
                }).catch(err => {
                    console.log("Unable to fetch user info", err.message);
                    return null;
                }))
            }
            else {
                return null
            }
        },
        { 
            retry: false,
            refetchInterval: 5000
        }
    );

    return (
        <AuthenticationContext.Provider
            value={{
                user,
                logout,
                userProfileQuery,
                loginByWalletMutation,
            }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export const useAuthenticationContext = () => useContext(AuthenticationContext);
