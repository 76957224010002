import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint no-param-reassign: ["error", { "props": false }] */

interface ticketOBJ {
    weekNumber: number,
    countDownValue: number,
}

const initialState:ticketOBJ = {
    weekNumber: 1,
    countDownValue: 0,
}

export const ticketObj = createSlice({
    name: 'ticketObj',
    initialState,
    reducers: {
        setTicketObj(state, action: PayloadAction<ticketOBJ>) {
            state.weekNumber = action.payload.weekNumber
            state.countDownValue = action.payload.countDownValue
        },
        setWeekNumber(state, action: PayloadAction<number>) {
            state.weekNumber = action.payload
        },
        setCountDownValue(state, action: PayloadAction<number>) {
            state.countDownValue = action.payload
        },
    }
});

export const { setTicketObj, setWeekNumber, setCountDownValue } = ticketObj.actions
export default ticketObj.reducer;
