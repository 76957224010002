import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint no-param-reassign: ["error", { "props": false }] */

interface ticketOBJ {
    weekNumber: number,
    countDownValue: number,
    superNovaTicketUser: number,
}

const initialState:ticketOBJ = {
    weekNumber: 1,
    countDownValue: 0,
    superNovaTicketUser: 0
}

export const superNovaTicket = createSlice({
    name: 'superNovaTicket',
    initialState,
    reducers: {
        setSuperNovaTicketObj(state, action: PayloadAction<ticketOBJ>) {
            state.weekNumber = action.payload.weekNumber
            state.countDownValue = action.payload.countDownValue
        },
        setSuperNovaWeekNumber(state, action: PayloadAction<number>) {
            state.weekNumber = action.payload
        },
        setSuperNovaCountDownValue(state, action: PayloadAction<number>) {
            state.countDownValue = action.payload
        },
        setSuperNovaTicketUser(state, action: PayloadAction<number>) {
            state.superNovaTicketUser = action.payload
        }
    }
});

export const { setSuperNovaTicketObj, setSuperNovaWeekNumber, setSuperNovaCountDownValue, setSuperNovaTicketUser } = superNovaTicket.actions
export default superNovaTicket.reducer;
