import { BreakpointsOptions } from "@mui/material";
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
      xs: true; // removes the `xs` breakpoint
      sm: true;
      md: true;
      lg: true;
      xl: true;
      desktop: true;
      laptop: true;
      hd: true;
    }
  }

const breakpoints: BreakpointsOptions = {
    values: {
        xs: 0,
        sm: 480,
        md: 768,
        desktop: 1024,
        laptop: 1366,
        lg: 1280,
        hd: 1400,
        xl: 1536,
    }
}

export default breakpoints