import { useContext, useEffect, useState } from 'react';
import React, { useCallback } from 'react';
import { BigNumber, ethers } from 'ethers';
import useLocalStorage from '../../hooks/useLocalStorage';
import { localStorageKeys } from '../../config/config';
import Web3Modal from "web3modal";

export type ReaderProvider = ethers.providers.InfuraProvider;

interface IWalletContext {
    address?: string;
    balance?: BigNumber;
    signerProviderApi?: ethers.providers.Web3Provider;
    readerProviderApi?: ReaderProvider;
    chainId?: number | string;
    onConnect: () => void;
    ready: boolean;
    disconnect: () => void;
    setAddress: (walletAddress: string) => void;
    signMessage: (message: string) => Promise<string> | undefined;
    subscribeProvider: () => void;
}

const WalletContext = React.createContext<IWalletContext>({} as IWalletContext);
const defaultEnvChainId = parseInt(process.env.REACT_APP_CHAIN_ID ?? '0x1');
const web3Modal = new Web3Modal({
    network: process.env.REACT_APP_ETH_ENV, // optional
    cacheProvider: true, // optional
});

function createReaderProvider(chainid: number = defaultEnvChainId): ReaderProvider | null {
    try {
        return new ethers.providers.InfuraProvider(chainid, {
            projectId: process.env.NEXT_PUBLIC_INFURA_PROJECT_SECRET
        });
    } catch (error) {
        return null;
    }
}

interface Props {
    children: React.ReactNode
}

export const WalletProvider: React.FC<Props> = ({ children }) => {
    const [address, setAddress] = React.useState<string | undefined>(undefined);
    const [balance, setBalance] = React.useState<BigNumber>();
    const [ready] = React.useState(false);
    const [chainId, setChainId] = React.useState<number | undefined>(undefined);
    const [w3Provider, setW3Provider] = useState<any>();
    const [signerProviderApi, setSignerProviderApi] = useState<ethers.providers.Web3Provider>();
    const [, setLocalStarkKey] = useLocalStorage(localStorageKeys.starkKey, '');
    const [, setWalletAddress] = useLocalStorage(localStorageKeys.walletAddress, '');
    const [readerProviderApi, setReaderProvider] = useState<ReaderProvider | null>(
        createReaderProvider()
    )

    const subscribeProvider = useCallback(async (provider: any) => {
        if (!provider.on) {
            return;
        }

        provider.on('accountsChanged', async (accounts: string[]) => {
            setAddress(accounts[0]);
        });

        provider.on('chainChanged', async (chainId: string) => {
            setChainId(parseInt(chainId));
        });
    }, []);

    useEffect(() => {
        if (chainId) {
            setReaderProvider(createReaderProvider(chainId));
        }
    }, [chainId]);

    const reset = () => {
        setAddress(undefined);
        setChainId(undefined);
    };

    const disconnect = useCallback(async () => {
        if (!web3Modal) {
            return;
        }
        if (w3Provider?.close) {
            await w3Provider.close();
        }
        web3Modal.clearCachedProvider();
        reset();
    }, [address, setLocalStarkKey, setWalletAddress, w3Provider]);

    const getBalanceETH = React.useCallback(() => {
        if (!address) return;
        return readerProviderApi?.getBalance(address);
    }, [readerProviderApi, address]);

    const initializeSubcribeProvider = async () => {
        const w3provider = await web3Modal.connect();
        await subscribeProvider(w3provider);
    };

    const onConnect = async () => {
        reset();
        const w3provider = await web3Modal.connect();
        await subscribeProvider(w3provider);
        const providerApi = new ethers.providers.Web3Provider(w3provider);
        const accounts = await providerApi.listAccounts();
        const address = accounts[0];
        const network = await providerApi.getNetwork();
        setW3Provider(w3provider);
        setSignerProviderApi(providerApi);
        setChainId(network.chainId);
        setAddress(address);
    };

    const onSetWalletAddress = (walletAddress: string) => {
        setAddress(walletAddress);
    };

    const signMessage = (message: string) => {
        return signerProviderApi?.getSigner().signMessage(message);
    };

    React.useEffect(() => {
        getBalanceETH()
            ?.then(setBalance)
            .catch(() => null);
    }, [getBalanceETH]);

    return (
        <WalletContext.Provider
            value={{
                address,
                signerProviderApi: signerProviderApi,
                readerProviderApi: readerProviderApi as any,
                chainId,
                onConnect,
                ready,
                disconnect,
                signMessage,
                balance,
                setAddress: onSetWalletAddress,
                subscribeProvider: initializeSubcribeProvider
            }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWalletContext = () => useContext(WalletContext);
