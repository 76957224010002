import axios from 'axios';
import config from '../config/config';

export interface IResponseError {
    httpCode: number | undefined;
    errors: [{ code: string; title: string; detail: string }];
}

const apiClient = axios.create({
    baseURL: config.api.solarFestival,
    timeout: 10000,
    headers: {
        accept: 'application/json',
        'Content-type': 'application/json'
    },
    withCredentials: true
})

export const apiClientMyriaCore = axios.create({
    baseURL: config.api.myria,
    timeout: 10000,
    headers: {
        accept: 'application/json',
        'Content-type': 'application/json'
    },
    withCredentials: true
})

export const noCacheApiClient = axios.create({
    baseURL: config.api.solarFestival,
    timeout: 10000,
    headers: {
        accept: 'application/json',
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache'
    },
    withCredentials: true
});

export default apiClient;
