import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint no-param-reassign: ["error", { "props": false }] */

interface userTicket {
    availableStars: number,
    userTicketBought: number,
}

const initialState:userTicket = {
    availableStars: 0,
    userTicketBought: 0,
}

export const userTicketSlice = createSlice({
    name: 'userTicket',
    initialState,
    reducers: {
        setUserTicket: (state, action: PayloadAction<userTicket>) => {
            state.availableStars = action.payload.availableStars;
            state.userTicketBought = action.payload.userTicketBought;
        },
        setAvailableStar: (state, action: PayloadAction<userTicket>) => {
            state.availableStars = action.payload.availableStars;
        },
        setUserTicketBought: (state, action: PayloadAction<userTicket>) => {
            state.userTicketBought = action.payload.userTicketBought;
        },
    },
});

export const {
    setUserTicket,
    setAvailableStar,
    setUserTicketBought,
} = userTicketSlice.actions;

export default userTicketSlice.reducer;
